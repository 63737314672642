import React from "react";
import { useSession } from "@/hooks/useSession";
import { Link, type PageProps } from "gatsby";
import { ROLES } from "schema";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "ui/components/dropdown-menu";

import { userNavigation } from "../const";

interface LayoutProps extends Omit<PageProps, "children"> {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

export const ProfileDropdown: React.FC<LayoutProps> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data, isLoading, ...rest } = useSession();

  const url = new URL(process.env.GATSBY_SERVER_URL || "");

  if (isLoading) {
    return null;
  }

  const isAdmin = data?.user?.roles.includes(ROLES.ADMIN);
  const isCreator = data?.user?.roles.includes(ROLES.CREATOR);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {userNavigation.map((item) => (
            <Link to={item.href} key={item.href}>
              <DropdownMenuItem className="flex items-center">
                <div className="mr-2 h-4 w-4 [&_.material-symbols-outlined]:text-[1.3em]">
                  <span className="material-symbols-outlined">{item.icon}</span>
                </div>
                <span>{item.name}</span>
              </DropdownMenuItem>
            </Link>
          ))}
          {(isAdmin || isCreator) && (
            <Link to="/creator/modules/">
              <DropdownMenuItem>
                <div className="mr-2 h-4 w-4 [&_.material-symbols-outlined]:text-[1.3em]">
                  <span className="material-symbols-outlined">
                    deployed_code
                  </span>
                </div>
                <span>Creator's Portal</span>
              </DropdownMenuItem>
            </Link>
          )}
          {isAdmin && (
            <Link to="/admin/modules/">
              <DropdownMenuItem>
                <div className="mr-2 h-4 w-4 [&_.material-symbols-outlined]:text-[1.3em]">
                  <span className="material-symbols-outlined">
                    shield_person
                  </span>
                </div>
                <span>Admin Portal</span>
              </DropdownMenuItem>
            </Link>
          )}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <Link to={`${url.protocol}//${url.host}/auth/logout/`}>
          <DropdownMenuItem>
            <div className="mr-2 h-4 w-4 [&_.material-symbols-outlined]:text-[1.3em]">
              <span className="material-symbols-outlined">logout</span>
            </div>
            <span>Log out</span>
          </DropdownMenuItem>
        </Link>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
