import React from "react";
import { useSession } from "@/hooks/useSession";
import { Link } from "gatsby";
import { ROLES } from "schema";
import { Button } from "ui/components/button";
import { SmallLoader } from "ui/components/small-loader";

import { ErrorPane } from "./ErrorPane";

export const NoAuthDisplay: React.FC<{
  title?: string;
  description?: string;
  currentPath: string;
}> = ({
  currentPath,
  description = "Sorry, we couldn’t find the page you’re looking for.",
  title = "Unauthorised",
}) => {
  const redirectPath = currentPath
    ? encodeURIComponent(currentPath)
    : undefined;

  const url = new URL(process.env.GATSBY_SERVER_URL || "");

  return (
    <div className="grid h-full w-full grow place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-accent-foreground">401</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-5xl">
          {title}
        </h1>
        <p className="mt-2 text-base leading-7 text-muted-foreground">
          {description}
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button asChild>
            <Link to="/">Go back home</Link>
          </Button>
          <Button asChild variant="ghost">
            <a
              href={
                redirectPath
                  ? `${url.protocol}//${url.host}/auth/logout/?redirectToAuth=true&redirectPath=${redirectPath}`
                  : `${url.protocol}://${url.host}/auth/logout/?redirectToAuth=true`
              }
            >
              Authenticate <span aria-hidden="true">&nbsp;&rarr;</span>
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};

export const UnAuthorisedPane: React.FC<{
  children: React.ReactNode;
  permissions: ROLES[];
  currentPath: string;
}> = ({ children, permissions, currentPath }) => {
  const { isLoading, isLoggedIn, error, data } = useSession();

  if (isLoading) {
    return <SmallLoader />;
  }

  if (!permissions.length) {
    return <>{children}</>;
  }

  if (error) {
    return <ErrorPane error={error} />;
  }

  if (
    permissions.length &&
    permissions.includes(ROLES.ADMIN) &&
    !data?.user?.roles?.includes(ROLES.ADMIN)
  ) {
    return (
      <NoAuthDisplay
        currentPath={currentPath}
        description="Sorry, this page is available to Admins only."
      />
    );
  }

  if (
    permissions.length &&
    permissions.includes(ROLES.CREATOR) &&
    !(
      data?.user?.roles?.includes(ROLES.CREATOR) ||
      data?.user?.roles?.includes(ROLES.ADMIN)
    )
  ) {
    return (
      <NoAuthDisplay
        currentPath={currentPath}
        description="Sorry, this page is available to Creators only."
      />
    );
  }

  if (permissions.length && !isLoggedIn) {
    return (
      <NoAuthDisplay
        currentPath={currentPath}
        description="Sorry, this page is available to Users only."
      />
    );
  }

  return <>{children}</>;
};
