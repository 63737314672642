import React from "react";
import { AvatarDisplay } from "@/components/AvatarDisplay";
import { useSession } from "@/hooks/useSession";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, navigate, type PageProps } from "gatsby";
import { ROLES } from "schema";
import { cn } from "tailwind/utils";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionPrimitive,
} from "ui/components/accordion";
import { Icon } from "ui/components/icon";
import Logo from "ui/components/logo";
import { SmallLoader } from "ui/components/small-loader";
import { Toaster } from "ui/components/toaster";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "ui/components/tooltip";
import { useToast } from "ui/components/use-toast";

import { LAYOUT_TYPES, layoutNavigation, userNavigation } from "../const";
import { ApplicationLayout } from "./ApplicationLayout";
import { NoopLayout } from "./noop";
import { ProfileDropdown } from "./profile-dropdown";

interface LayoutProps extends Omit<PageProps, "children"> {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

const getLayoutKey = (path: string) => {
  if (path === "/" || !path.startsWith("/")) return;
  const pathArray = path.split("/");
  const pathKey = pathArray[1];

  switch (pathKey) {
    case "admin":
      return LAYOUT_TYPES.ADMIN;
    case "creator":
      return LAYOUT_TYPES.CREATOR;
    case "user":
      return LAYOUT_TYPES.PROFILE;
    case "auth":
      return LAYOUT_TYPES.AUTH;
    default:
      return undefined;
  }
};

export const GlobalLayout: React.FC<LayoutProps> = ({ children, ...props }) => {
  const {
    path,
    location: { search },
  } = props;

  const searchParams = new URLSearchParams(search);
  const redirectPath = searchParams.get("redirectPath");
  const errorMessage = searchParams.get("errorMessage");

  const layoutKey = getLayoutKey(path);

  const { toast } = useToast();
  const { data, error, isLoading } = useSession();

  const isAdmin = data?.user?.roles.includes(ROLES.ADMIN);
  const isCreator = data?.user?.roles.includes(ROLES.CREATOR);

  React.useEffect(() => {
    if (errorMessage) {
      toast({
        title: "Server Error.",
        description: errorMessage,
      });
      navigate(path, { replace: true });
    }
  }, [errorMessage]);

  React.useEffect(() => {
    if (layoutKey === LAYOUT_TYPES.AUTH) {
      if (!isLoading && data?.user && !error) {
        navigate(redirectPath || "/");
      }
    }
  }, [isLoading, data, error, redirectPath]);

  if (isLoading) {
    return (
      <div className="grid min-h-screen grid-cols-1">
        <div className="place-self-center px-6">
          <h1 className="mb-10 text-center text-3xl font-bold tracking-wider">
            Entering Educat.
          </h1>
          <SmallLoader />
        </div>
      </div>
    );
  }

  const Layout =
    layoutKey && layoutKey !== LAYOUT_TYPES.AUTH
      ? ApplicationLayout
      : NoopLayout;
  const navigation =
    layoutKey && layoutKey !== LAYOUT_TYPES.AUTH
      ? layoutNavigation[layoutKey]
      : undefined;

  const user = data?.user || {
    email: "tom@example.com",
    userProfile: {
      name: "Tom Cook",
      picture:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  };

  return (
    <TooltipProvider>
      <div className="flex h-screen min-h-screen w-screen flex-col">
        <Accordion
          type="single"
          collapsible
          className="sticky top-0 z-50 h-fit w-full"
        >
          <AccordionItem
            value="item-1"
            className="mx-auto max-w-7xl border-none px-4 sm:px-6 lg:px-8"
          >
            <div className="flex items-center justify-between">
              <div className="flex">
                <div className="my-1.5 flex flex-shrink-0 items-center rounded-2xl bg-background/5 bg-clip-padding px-3  py-1 backdrop-blur-sm backdrop-filter">
                  <Link to="/" className="flex items-center">
                    <Logo />
                  </Link>
                </div>
                {/* <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={cn(
                          item.current
                            ? "border-indigo-500 text-gray-900"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium",
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div> */}
              </div>
              {layoutKey !== LAYOUT_TYPES.AUTH && (
                <div className="hidden h-full space-x-4 sm:ml-6 sm:flex sm:items-center">
                  {data && (
                    <button
                      type="button"
                      className="relative inset-1 inline-flex aspect-square max-w-xs  flex-shrink-0 items-center justify-center rounded-full bg-background/5 bg-clip-padding px-1.5 text-sm text-muted-foreground ring-muted-foreground backdrop-blur-sm backdrop-filter hover:font-bold hover:text-foreground hover:ring-1"
                    >
                      <span className="sr-only">View notifications</span>
                      <Icon icon="notifications" aria-hidden="true" />
                    </button>
                  )}
                  {/* Profile dropdown */}
                  {data && (
                    <ProfileDropdown {...props}>
                      <button className="relative inset-1 inline-flex aspect-square max-w-xs  flex-shrink-0 items-center justify-center rounded-full bg-background/5 bg-clip-padding px-1.5 text-sm text-muted-foreground ring-muted-foreground backdrop-blur-sm backdrop-filter hover:font-bold hover:text-foreground hover:ring-1">
                        <span className="sr-only">Open user menu</span>
                        <AvatarDisplay
                          value={user?.profile?.avatar}
                          className="aspect-square h-8 w-8 rounded-full"
                        />
                      </button>
                    </ProfileDropdown>
                  )}

                  {!data && (
                    <Link
                      to={
                        redirectPath
                          ? `/auth/?redirectPath=${encodeURIComponent(
                              redirectPath,
                            )}`
                          : "/auth/"
                      }
                      className="flex-shrink-1 rounded- relative inline-flex  max-w-xs items-center justify-center rounded-lg border bg-background/5 bg-clip-padding px-3 py-2 text-base font-bold text-muted-foreground ring-muted-foreground backdrop-blur-sm backdrop-filter hover:text-foreground hover:ring-1"
                    >
                      Log In
                    </Link>
                  )}
                </div>
              )}
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                {data && (
                  <AccordionPrimitive.Header className="flex">
                    <AccordionPrimitive.Trigger
                      className={cn(
                        "flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180",
                        "group relative inline-flex aspect-square max-w-xs flex-shrink-0  items-center justify-center rounded-full bg-background/5 bg-clip-padding p-2 px-2 py-2 text-sm text-muted-foreground ring-muted-foreground backdrop-blur-sm backdrop-filter hover:font-bold hover:text-foreground hover:ring-1",
                      )}
                    >
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      <Bars3Icon
                        className="block h-6 w-6 group-data-[state=open]:hidden"
                        aria-hidden="true"
                      />
                      <XMarkIcon
                        className="hidden h-6 w-6 group-data-[state=open]:block"
                        aria-hidden="true"
                      />
                    </AccordionPrimitive.Trigger>
                  </AccordionPrimitive.Header>
                )}
                {!data && layoutKey !== LAYOUT_TYPES.AUTH && (
                  <Link
                    to={
                      redirectPath
                        ? `/auth/?redirectPath=${encodeURIComponent(
                            redirectPath,
                          )}`
                        : "/auth/"
                    }
                    className="flex-shrink-1 rounded- relative inline-flex  max-w-xs items-center justify-center rounded-lg border bg-background/5 bg-clip-padding px-3 py-2 text-base font-bold text-muted-foreground ring-muted-foreground backdrop-blur-sm backdrop-filter hover:text-foreground hover:ring-1"
                  >
                    Log In
                  </Link>
                )}
              </div>
            </div>

            <AccordionContent asChild>
              <div className="mx-4 rounded-lg border border-muted-foreground/50 bg-background/5 pb-3 pt-4 shadow-sm backdrop-blur-sm backdrop-filter">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <AvatarDisplay
                      value={user?.profile?.avatar}
                      className="aspect-square h-8 w-8 rounded-full"
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      {user?.profile?.username}
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      {"user.email"}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <Icon icon="notifications" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 grid grid-cols-1 space-y-1">
                  {userNavigation.map((item) => (
                    <AccordionPrimitive.Trigger asChild key={item.href}>
                      <Link
                        partiallyActive={true}
                        activeClassName="underline"
                        to={item.href}
                        className="flex items-center px-4 py-2 text-base font-medium text-muted-foreground"
                      >
                        <span className="material-symbols-outlined mr-1">
                          {item.icon}
                        </span>
                        {item.name}
                      </Link>
                    </AccordionPrimitive.Trigger>
                  ))}
                  {(isAdmin || isCreator) && (
                    <AccordionPrimitive.Trigger asChild>
                      <Link
                        partiallyActive={true}
                        activeClassName="underline"
                        className="flex items-center px-4 py-2 text-base font-medium text-muted-foreground"
                        to="/creator/modules/"
                      >
                        <div className="mr-2 h-4 w-4 [&_.material-symbols-outlined]:text-[1.3em]">
                          <span className="material-symbols-outlined">
                            deployed_code
                          </span>
                        </div>
                        <span>Creator's Portal</span>
                      </Link>
                    </AccordionPrimitive.Trigger>
                  )}
                  {isAdmin && (
                    <AccordionPrimitive.Trigger asChild>
                      <Link
                        to="/admin/modules/"
                        partiallyActive={true}
                        activeClassName="underline"
                        className="flex items-center px-4 py-2 text-base font-medium text-muted-foreground"
                      >
                        <div className="mr-2 h-4 w-4 [&_.material-symbols-outlined]:text-[1.3em]">
                          <span className="material-symbols-outlined">
                            shield_person
                          </span>
                        </div>
                        <span>Admin Portal</span>
                      </Link>
                    </AccordionPrimitive.Trigger>
                  )}
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <main className="h-[calc(100%-5rem)] w-full">
          <Layout {...props} navigation={navigation}>
            {children}
          </Layout>
        </main>
        <Toaster />
      </div>
    </TooltipProvider>
  );
};
