import { z } from "./xzod";

/**
 * Common Schema for Videos, Modules and Pathways
 */

export const CommonMetaSchema = z.object({
  _id: z.string().min(1),
  archived: z.date().nullable().optional(),
});
