import "tailwind/globals.css";

import * as React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { GatsbyBrowser } from "gatsby";

import { api, getClient } from "./src/api";
import { GlobalLayout } from "./src/layouts/global";

global.Websocket = global.Websocket || null;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 1000,
    },
  },
});

const trpcClient = getClient();

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  return (
    <api.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <GlobalLayout {...props}>{element}</GlobalLayout>
      </QueryClientProvider>
    </api.Provider>
  );
};
