import React from "react";
import { TRPCClientError } from "@trpc/client";
import { Link } from "gatsby";
import { Button } from "ui/components/button";

export const ErrorPane: React.FC<{
  error: any;
}> = ({ error }) => {
  let errorCode = "Unknown Error";
  let message = "Unknown Error Message";

  if (error instanceof TRPCClientError) {
    errorCode = error.data?.httpStatus;
    message = error.message;
  } else if (error instanceof Error) {
    errorCode = error.name;
    message = error.message;
  }

  return (
    <div className="grid h-full w-full grow place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-accent-foreground">
          {errorCode}
        </p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-5xl">
          Oops.
        </h1>
        <p className="mt-2 text-base leading-7 text-muted-foreground">
          Something went wrong on our side. Please try again later.
        </p>
        <p className="mt-1 text-xs text-muted-foreground/70">{message}</p>
        <div className="mt-3 flex items-center justify-center gap-x-6">
          <Button asChild variant="ghost">
            {/* TODO: setup sentry */}
            <a
              href={`mailto:test@example.com?subject=ClientError&body=${JSON.stringify(
                error,
                null,
                2,
              )}stampedat${Date.now()}`}
            >
              Contact Us Directly &nbsp; <span aria-hidden="true">&rarr;</span>
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};
