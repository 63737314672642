import React from "react";
import { api } from "@/api";
import { AvatarSchema } from "schema";

export const AvatarDisplay: React.FC<{
  value: typeof AvatarSchema;
  className: string;
}> = ({ value, className }) => {
  const enabled = !!value && typeof value !== "string";

  const { data, isInitialLoading } = api.utils.getResourceUrl.useQuery(
    {
      contentType: enabled ? value.contentType : "",
      filename: enabled ? value.key : "",
    },
    { enabled },
  );

  return (
    <img
      className={className}
      src={data?.downloadUrl || value}
      alt=""
      referrerPolicy="no-referrer"
    />
  );
};
