import { CommonMetaSchema } from "./common";
import { z } from "./xzod";

/**
 * Pathway Schemas
 */

export enum ModuleEntryType {
  MultiModule = "multi-module",
  SingleModule = "single-module",
}

export const ModuleEntrySchema = z.object({
  moduleId: z.string(),
  version: z.string(),
});

export const StageEntrySchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal(ModuleEntryType.MultiModule),
    modules: z.array(ModuleEntrySchema),
  }),
  z
    .object({
      type: z.literal(ModuleEntryType.SingleModule),
    })
    .merge(ModuleEntrySchema),
]);

export const PathwayPropertiesSchema = z.object({
  title: z.string().min(2),
  description: z.string().min(2).meta({ component: "textarea" }),
  stages: z.array(StageEntrySchema),
  creatorComments: z.string().optional().meta({ component: "textarea" }),
});

export const PathwayGeneratedPropertiesSchema = z.object({
  publishedAt: z.union([z.date(), z.undefined(), z.null()]).optional(),
  version: z.number().min(0).default(0),
  minorVersion: z.number().min(0).default(0),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const PathwayFormSchema = PathwayPropertiesSchema;

export const PathwaySchema = PathwayPropertiesSchema.merge(
  PathwayGeneratedPropertiesSchema,
);

export const PathwayDocumentSchema = z
  .object({
    versions: z.array(
      PathwaySchema.extend({ publishedAt: z.date() }).or(
        PathwaySchema.partial().extend({
          publishedAt: z.null().or(z.undefined()),
        }),
      ),
    ),
  })
  .merge(CommonMetaSchema);
