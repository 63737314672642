import React from "react";
import { type PageProps } from "gatsby";
import { ROLES } from "schema";

import { DashboardLink } from "../components/DashboardLink";
import { UnAuthorisedPane } from "../components/Unauthorised";

interface LayoutProps extends Omit<PageProps, "children"> {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  navigation:
    | Array<{
        name: string;
        href: string;
        current: boolean;
        icon: string;
      }>
    | undefined;
}

export const ApplicationLayout: React.FC<LayoutProps> = ({
  children,
  navigation,
  path,
}) => {
  return (
    <UnAuthorisedPane permissions={[ROLES.USER]} currentPath={path}>
      <div className="mx-auto flex h-full max-w-7xl grow flex-col pb-6 pt-2 md:flex-row">
        {/* Left column */}
        <div className="md:basis-2/12">
          <section className="h-full" aria-labelledby="creator-navigation">
            <h2 className="sr-only" id="section-2-title">
              Creator Navigation
            </h2>
            <nav className="h-full border-r p-6">
              {navigation?.map((item) => (
                <DashboardLink
                  key={item.href}
                  {...item}
                  className="block p-2 text-base font-medium text-muted-foreground hover:text-foreground"
                  activeClassName="underline underline-offset-2 decoration-2 text-foreground"
                />
              ))}
            </nav>
          </section>
        </div>

        {/* Right column */}
        <div className="flex-none md:basis-10/12">
          <section aria-labelledby="main-section" className="h-full">
            <h2 className="sr-only" id="section-1-title">
              Main Section
            </h2>
            {children}
          </section>
        </div>
      </div>
    </UnAuthorisedPane>
  );
};
