import * as React from "react";
import { useToast } from "ui/components/use-toast";

import { api } from "../api";

export const useSession = () => {
  const { toast } = useToast();
  const { data, error, isError, isInitialLoading } =
    api.users.getSession.useQuery(undefined, {
      cacheTime: 0,
    });

  React.useEffect(() => {
    error &&
      toast({
        itemID: "auth-error",
        title: "Uhoh!",
        description: `Something Went Wrong. Please contact customer support. ${error.message}`,
      });
  }, [isError, error]);

  return {
    data,
    error,
    isLoading: isInitialLoading,
    isError,
    isLoggedIn: !!data?.user && !isInitialLoading,
  };
};
