import * as React from "react";
import { Link } from "gatsby";
import { cn } from "tailwind/utils";

type DashboardLinkProps = {
  name: string;
  href: string;
  className?: string;
  activeClassName?: string;
  as?: string;
};

export const DashboardLink: React.FC<DashboardLinkProps> = ({
  href,
  name,
  className,
  activeClassName,
}) => {
  return (
    <Link
      to={href}
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        return {
          className: cn(
            className,
            isPartiallyCurrent || isCurrent ? activeClassName : "",
          ),
        };
      }}
      partiallyActive={true}
    >
      {name}
    </Link>
  );
};
