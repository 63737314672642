import { z } from "./xzod";

export const AUTH_ERROR_MESSAGES = {
  SERVER: "Something went wrong, please contact us directly.",
  GOOGLE: "Please login with Google.",
  MAGIC_LINK: "Please login with Email.",
  NOT_FOUND: "Account not found. Please proceed to register.",
  EXISTS: "Account already exists. Please proceed to login.",
  INVALID_CREDS: "Invalid credentials.",
  INVALID_CODE: "Invalid code. Please request a new one.",
  EXPIRED_CODE: "Code has expired. Please request a new one.",
};

export enum EMAIL_MODES {
  DOES_NOT_EXIST = "does-not-exist",
  EXISTS_WITHOUT_PASSWORD = "exists-without-password",
  EXISTS_WITH_PASSWORD = "exists-with-password",
}

export const LoginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8).max(20),
});

export const SetPasswordSchema = z
  .object({
    email: z.string().email(),
    password: z.string().min(8).max(20),
    passwordAgain: z.string().min(8).max(20),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.passwordAgain) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Passwords do not match.",
        path: ["passwordAgain"],
      });
    }
    return data;
  });

export const RegisterSchema = z
  .object({
    email: z.string().email(),
    password: z.string().min(8).max(20),
    passwordAgain: z.string().min(8).max(20),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.passwordAgain) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Passwords do not match.",
        path: ["passwordAgain"],
      });
    }
    return true;
  });

export const ChangePasswordSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8).max(20),
  passwordAgain: z.string().min(8).max(20),
});

export const LostPasswordSchema = z.object({
  email: z.string().email(),
});

export const GoogleJsonResponseSchema = z.object({
  sub: z.string(),
  name: z.string(),
  given_name: z.string(),
  family_name: z.string(),
  picture: z.string().url(),
  email: z.string().email(),
  email_verified: z.boolean(),
  locale: z.string(),
  // hd: "hello.ai",
  hd: z.string(),
});
// note: id and sub is the same
export const GoogleResponseSchema = z.object({
  id: z.string(),
  displayName: z.string(),
  name: z.object({
    familyName: z.string(),
    givenName: z.string(),
  }),
  emails: z.array(
    z.object({
      value: z.string().email(),
      verified: z.boolean(),
    }),
  ),
  photos: z.array(
    z.object({
      value: z.string().url(),
    }),
  ),
  provider: z.literal("google"),
  _raw: z.string(),
  _json: GoogleJsonResponseSchema,
});

export enum ROLES {
  ADMIN = "admin",
  CREATOR = "creator",
  USER = "user",
}

export const AuthLocalEntrySchema = z.object({
  password: z.string(),
});

export const AuthGoogleEntrySchema = z.object({
  googleId: z.string(),
  accessToken: z.string(),
  refreshToken: z.union([z.string(), z.undefined(), z.null()]).optional(),
});

export const AuthEmailEntrySchema = z.object({
  email: z.string().email(),
  google: AuthGoogleEntrySchema.optional(),
  local: AuthLocalEntrySchema.optional(),
});

export enum PURCHASE_TYPES {
  MODULE_PURCHASE = "module:purchase",
  MODULE_SUBSCRIPTION = "module:subscription",
}

export const UserPurchasedSchema = z.object({
  _id: z.string(),
  purchaseType: z.nativeEnum(PURCHASE_TYPES),
  purchasedAt: z.date().nullable(),
  sessionId: z.string(),
  invoiceId: z.string().nullable(),
});

export const AvatarSchema = z.union([
  z.string().url(),
  z.object({
    key: z.string(),
    contentType: z.string(),
  }),
  z.undefined(),
  z.null(),
]);

export const UserProfileSchema = z.object({
  userhandle: z.string(),
  avatar: AvatarSchema,
});

export const UserProfileFormSchema = UserProfileSchema;

export const UserSchema = z.object({
  _id: z.string().min(1),
  emails: z.array(AuthEmailEntrySchema),
  lastLogin: z.date(),
  createdAt: z.date(),
  roles: z.array(z.nativeEnum(ROLES)),
  archived: z.date().nullable().optional(),
  customerId: z.string().nullable().optional(),
  purchased: z.array(UserPurchasedSchema),
  profile: UserProfileSchema,
});

export const isOnboarded = (data: any) => {
  return UserProfileSchema.safeParse(data).success;
};

const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(4, "0");

const randomIntFromInterval = (min: number, max: number) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const MakeUserProfileArgsSchema = z.object({
  email: z.string(),
  picture: z.string().url().optional(),
});

export const makeUserProfile = (
  data: z.infer<typeof MakeUserProfileArgsSchema>,
): z.infer<typeof UserProfileSchema> => {
  const userhandle = `${data.email.split("@")[0]}${pad(
    randomIntFromInterval(1, 1000),
  )}`;
  const avatar = data.picture;

  return { userhandle, avatar };
};
