import { CommonMetaSchema } from "./common";
import {
  ExtendedSegmentVideoFormSchema,
  SegmentMCQFormSchema,
  SegmentMultiMCQFormSchema,
  SegmentShortAnswerFormSchema,
} from "./segment";
import { UserSchema } from "./user";
import { z } from "./xzod";

/**
 * Module Schemas
 */

export const EntrySchema = z.object({
  _id: z.string(),
  version: z.number(),
});

export const SectionEntrySchema = z.object({
  title: z.string().min(2),
  description: z.string().meta({ component: "textarea" }),
  subsections: z.array(EntrySchema),
});

export const ModulePropertiesSchema = z.object({
  title: z.string().min(2),
  description: z.string().meta({ component: "textarea" }),
  sections: z.array(SectionEntrySchema),
  creators: z.array(z.string()),
  creatorComments: z.string().meta({ component: "textarea" }),
  purchasePrice: z.number().min(0),
  coverImage: z.string(),
});

export const ModuleGeneratedPropertiesSchema = z.object({
  publishedAt: z.union([z.date(), z.undefined(), z.null()]).optional(),
  version: z.number().min(0).default(0),
  minorVersion: z.number().min(0).default(0),
  createdAt: z.date(),
  updatedAt: z.date(),
  coverImageSources: z
    .array(
      z.object({
        Key: z.string(),
        ContentType: z.string(),
      }),
    )
    .optional(),
});

export const ModuleFormSchema = ModulePropertiesSchema;

export const ModuleSchema = ModulePropertiesSchema.merge(
  ModuleGeneratedPropertiesSchema,
);

export const ModuleDocumentSchema = z
  .object({
    versions: z.array(
      ModuleSchema.extend({ publishedAt: z.date() }).or(
        ModuleSchema.partial().extend({
          publishedAt: z.null().or(z.undefined()),
        }),
      ),
    ),
  })
  .merge(CommonMetaSchema);

export const ExtendedSubSectionProperties = {
  _id: z.string(),
  version: z.number(),
  minorVersion: z.number(),
};

export const ExtendedModuleSchema = ModuleSchema.extend({
  creatorInfo: z.array(UserSchema.omit({ emails: true })),
  sections: z.array(
    SectionEntrySchema.extend({
      subsections: z.array(
        z.discriminatedUnion("segmentType", [
          SegmentShortAnswerFormSchema.extend(ExtendedSubSectionProperties),
          SegmentMultiMCQFormSchema.extend(ExtendedSubSectionProperties),
          SegmentMCQFormSchema.extend(ExtendedSubSectionProperties),
          ExtendedSegmentVideoFormSchema.extend(ExtendedSubSectionProperties),
        ]),
      ),
    }),
  ),
}).partial();
