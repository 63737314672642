import { CommonMetaSchema } from "./common";
import { z } from "./xzod";

export enum SegmentType {
  MCQ_QUESTIONS = "MCQ_QUESTIONS",
  MULTI_MCQ_QUESTIONS = "MULTI_MCQ_QUESTIONS",
  SHORT_ANSWER = "SHORT_ANSWER",
  VIDEO = "VIDEO",
}

/**
 * Module Schemas
 */

export const SegmentGeneratedPropertiesSchema = z.object({
  publishedAt: z.union([z.date(), z.undefined(), z.null()]).optional(),
  version: z.number().min(0).default(0),
  minorVersion: z.number().min(0).default(0),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const SegmentMCQProperties = z.object({
  title: z.string().optional(),
  mcqQuestions: z
    .array(
      z.object({
        mcqQuestion: z.string().meta({
          displayName: "Question",
        }),
        mcqAnswer: z.number().meta({
          displayName: "Answer",
        }),
        mcqOptions: z.array(z.string()).meta({
          displayName: "Options",
        }),
      }),
    )
    .meta({
      displayName: "Questions",
    }),
  creatorComments: z.string().meta({ component: "textarea" }),
});

export const SegmentMCQDocumentSchema = z
  .object({
    segmentType: z.literal(SegmentType.MCQ_QUESTIONS),
    versions: z.array(
      z.union([
        SegmentMCQProperties.partial()
          .merge(SegmentGeneratedPropertiesSchema)
          .merge(
            z.object({
              publishedAt: z.union([z.undefined(), z.null()]),
            }),
          ),
        SegmentMCQProperties.merge(SegmentGeneratedPropertiesSchema).merge(
          z.object({
            publishedAt: z.date(),
          }),
        ),
      ]),
    ),
  })
  .merge(CommonMetaSchema);

export const SegmentMultiMCQProperties = z.object({
  title: z.string().optional(),
  mmcqQuestions: z
    .array(
      z.object({
        mmcqQuestion: z.string().meta({
          displayName: "Question",
        }),
        mmcqAnswer: z.array(z.number()).meta({
          displayName: "Answer",
        }),
        mmcqOptions: z.array(z.string()).meta({
          displayName: "Options",
        }),
      }),
    )
    .meta({
      displayName: "Question",
    }),
  creatorComments: z.string().meta({ component: "textarea" }),
});

export const SegmentMultiMCQDocumentSchema = z
  .object({
    segmentType: z.literal(SegmentType.MULTI_MCQ_QUESTIONS),
    versions: z.array(
      z.union([
        SegmentMultiMCQProperties.partial()
          .merge(SegmentGeneratedPropertiesSchema)
          .merge(
            z.object({
              publishedAt: z.union([z.undefined(), z.null()]),
            }),
          ),
        SegmentMultiMCQProperties.merge(SegmentGeneratedPropertiesSchema).merge(
          z.object({
            publishedAt: z.date(),
          }),
        ),
      ]),
    ),
  })
  .merge(CommonMetaSchema);

export const SegmentShortAnswerProperties = z.object({
  title: z.string().optional(),
  shortQuestion: z.string().meta({
    displayName: "Question",
  }),
  shortModelAnswer: z.string().meta({
    displayName: "Answer",
  }),
  creatorComments: z.string().meta({ component: "textarea" }),
});

export const SegmentShortAnswerDocumentSchema = z
  .object({
    segmentType: z.literal(SegmentType.SHORT_ANSWER),
    versions: z.array(
      z.union([
        SegmentShortAnswerProperties.partial()
          .merge(SegmentGeneratedPropertiesSchema)
          .merge(
            z.object({
              publishedAt: z.union([z.undefined(), z.null()]),
            }),
          ),
        SegmentShortAnswerProperties.merge(
          SegmentGeneratedPropertiesSchema,
        ).merge(
          z.object({
            publishedAt: z.date(),
          }),
        ),
      ]),
    ),
  })
  .merge(CommonMetaSchema);

export const SegmentVideoProperties = z.object({
  title: z.string().optional(),
  description: z.string().meta({ component: "textarea" }),

  // uploaded talking head video properties
  videoFilename: z.string(),
  videoContentType: z.string(),

  // uploaded resource video properties
  resourceFilename: z.string(),
  resourceContentType: z.string(),

  creatorComments: z.string().meta({ component: "textarea" }),
});

export const OptimisedSegmentVideoProperties = SegmentVideoProperties.extend({
  videoSources: z
    .array(
      z.union([
        z.object({
          src: z.string(),
          type: z.string(),
          jobStatus: z.string(),
        }),
        z.string(),
      ]),
    )
    .optional(),

  resourceSources: z
    .array(
      z.union([
        z.object({
          src: z.string(),
          type: z.string(),
          jobStatus: z.string(),
        }),
        z.string(),
      ]),
    )
    .optional(),

  subtitleSources: z
    .array(
      z.union([
        z.object({
          src: z.string(),
          type: z.string(),
          jobStatus: z.string(),
          jobStartTime: z.date(),
        }),
        z.string(),
      ]),
    )
    .optional(),
});

export const SegmentVideoDocumentSchema = z
  .object({
    segmentType: z.literal(SegmentType.VIDEO),
    versions: z.array(
      z.union([
        OptimisedSegmentVideoProperties.partial()
          .merge(SegmentGeneratedPropertiesSchema)
          .merge(
            z.object({
              publishedAt: z.union([z.undefined(), z.null()]),
            }),
          ),
        OptimisedSegmentVideoProperties.merge(
          SegmentGeneratedPropertiesSchema,
        ).merge(
          z.object({
            publishedAt: z.date(),
          }),
        ),
      ]),
    ),
  })
  .merge(CommonMetaSchema);

export const SegmentShortAnswerFormSchema = SegmentShortAnswerProperties.extend(
  {
    segmentType: z.literal(SegmentType.SHORT_ANSWER),
  },
);

export const SegmentMultiMCQFormSchema = SegmentMultiMCQProperties.extend({
  segmentType: z.literal(SegmentType.MULTI_MCQ_QUESTIONS),
});

export const SegmentMCQFormSchema = SegmentMCQProperties.extend({
  segmentType: z.literal(SegmentType.MCQ_QUESTIONS),
});

export const SegmentVideoFormSchema = SegmentVideoProperties.extend({
  segmentType: z.literal(SegmentType.VIDEO),
});

export const ExtendedSegmentVideoFormSchema =
  OptimisedSegmentVideoProperties.extend({
    segmentType: z.literal(SegmentType.VIDEO),
  });

/**
 * Video Schemas
 */

export const SegmentFormSchema = z.discriminatedUnion("segmentType", [
  SegmentShortAnswerFormSchema,
  SegmentMultiMCQFormSchema,
  SegmentMCQFormSchema,
  SegmentVideoFormSchema,
]);

export const SegmentShortAnswerDisplaySchema =
  SegmentShortAnswerFormSchema.extend({
    _id: z.string(),
    version: z.number(),
    minorVersion: z.number(),
  });
export const SegmentMultiMCQDisplaySchema = SegmentMultiMCQFormSchema.extend({
  _id: z.string(),
  version: z.number(),
  minorVersion: z.number(),
});
export const SegmentMCQDisplaySchema = SegmentMCQFormSchema.extend({
  _id: z.string(),
  version: z.number(),
  minorVersion: z.number(),
});
export const VideoDisplaySchema = ExtendedSegmentVideoFormSchema.extend({
  _id: z.string(),
  version: z.number(),
  minorVersion: z.number(),
});

export const SegmentDisplaySchema = z.discriminatedUnion("segmentType", [
  SegmentShortAnswerDisplaySchema,
  SegmentMultiMCQDisplaySchema,
  SegmentMCQDisplaySchema,
  VideoDisplaySchema,
]);

export const SegmentFormSchemaMap = {
  [SegmentType.SHORT_ANSWER]: SegmentShortAnswerFormSchema,
  [SegmentType.MULTI_MCQ_QUESTIONS]: SegmentMultiMCQFormSchema,
  [SegmentType.MCQ_QUESTIONS]: SegmentMCQFormSchema,
  [SegmentType.VIDEO]: SegmentVideoFormSchema,
};

export const ExtendedSegmentFormSchema = z.discriminatedUnion("segmentType", [
  SegmentShortAnswerProperties.merge(SegmentGeneratedPropertiesSchema)
    .partial()
    .extend({
      _id: z.string(),
      publishedAt: z.union([z.undefined(), z.null(), z.date()]),
      archived: z.union([z.undefined(), z.null(), z.date()]),
      latestVersion: z.number(),
      segmentType: z.literal(SegmentType.SHORT_ANSWER),
    }),
  SegmentMultiMCQProperties.merge(SegmentGeneratedPropertiesSchema)
    .partial()
    .extend({
      _id: z.string(),
      publishedAt: z.union([z.undefined(), z.null(), z.date()]),
      archived: z.union([z.undefined(), z.null(), z.date()]),
      latestVersion: z.number(),
      segmentType: z.literal(SegmentType.MULTI_MCQ_QUESTIONS),
    }),
  SegmentMCQProperties.merge(SegmentGeneratedPropertiesSchema)
    .partial()
    .extend({
      _id: z.string(),
      publishedAt: z.union([z.undefined(), z.null(), z.date()]),
      archived: z.union([z.undefined(), z.null(), z.date()]),
      latestVersion: z.number(),
      segmentType: z.literal(SegmentType.MCQ_QUESTIONS),
    }),
  OptimisedSegmentVideoProperties.merge(SegmentGeneratedPropertiesSchema)
    .partial()
    .extend({
      _id: z.string(),
      publishedAt: z.union([z.undefined(), z.null(), z.date()]),
      archived: z.union([z.undefined(), z.null(), z.date()]),
      latestVersion: z.number(),
      segmentType: z.literal(SegmentType.VIDEO),
    }),
]);

export const SegmentDocumentSchema = z.discriminatedUnion("segmentType", [
  SegmentShortAnswerDocumentSchema,
  SegmentMultiMCQDocumentSchema,
  SegmentMCQDocumentSchema,
  SegmentVideoDocumentSchema,
]);
