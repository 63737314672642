import React from "react";
import { cn } from "tailwind/utils";

export const Logo: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={cn("text-2xl font-extrabold tracking-widest", className)}>
      <span>educat.</span>
    </div>
  );
};

export const PictureLogo: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <div className={cn("h-10, w-10", className)}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 566.93 566.93"
        xmlSpace="preserve"
      >
        <g>
          <path
            className="fill-foreground"
            d="M359.23,275.62c-1.18-4.82-3.27-11.75-7.2-19.7c-0.08-5.89-0.65-37.71-8.02-53.26c-1.47-3.1-4.3-8.87-9.5-9.98
		c-2.79-0.6-5.25,0.36-7.56,1.46c-10.83,5.17-19.13,13.83-25.83,22.05c-2.45,3.01-5.7,7.2-9.27,12.44
		c-4.12-1.07-9.83-2.06-16.64-1.76c-4.72,0.21-8.8,0.99-12.05,1.86c-4.17-6.84-8.22-12.21-11.39-16.07
		c-10.07-12.28-16.89-16.33-21.15-18.36c-3.85-1.83-6.04-2.84-8.83-2.3c-7.06,1.36-10.48,11.23-13.2,19.1
		c-4.48,12.92-4.76,24.94-4.92,35.27c-0.05,3.32,0,6.07,0.06,7.95c-4.36,8.13-6.74,15.35-8.11,20.53
		c-6.31,23.97-1.75,45.6,4.61,55.11c1.95,2.91,4.28,5.34,4.28,5.34c0,0,5.48,5.7,13.81,9.22c23.04,9.75,61.96,8.76,61.97,8.76
		c50.22-1.29,62.84-11.49,67.85-16.38c3.56-3.48,5.73-6.67,6.18-7.34C367.57,309.81,361.47,284.81,359.23,275.62z"
          ></path>
          <path
            className="fill-background"
            d="M305.15,318.87c-1.08-0.86-2.66-0.69-3.53,0.4c-3.34,4.2-8.42,6.21-13.26,5.27c-4.43-0.87-7.18-4-8.31-5.57
		v-7.45c1.78-0.91,3.05-2.66,3.97-3.95c0.95-1.33,1.75-2.45,1.39-3.41c-0.59-1.57-3.8-1.58-7.72-1.59
		c-3.98-0.01-7.42-0.02-8.02,1.59c-0.35,0.97,0.47,2.08,1.46,3.41c0.89,1.21,2.17,2.93,3.91,3.87v7.52
		c-1.13,1.58-3.87,4.71-8.31,5.57c-4.84,0.95-9.92-1.07-13.26-5.27c-0.86-1.09-2.44-1.26-3.53-0.4c-1.08,0.86-1.26,2.44-0.4,3.53
		c3.76,4.72,9.22,7.39,14.81,7.39c1.11,0,2.23-0.11,3.35-0.32c4.61-0.9,7.83-3.44,9.85-5.64c2.02,2.2,5.25,4.74,9.85,5.64
		c1.11,0.22,2.23,0.32,3.35,0.32c5.59,0,11.04-2.67,14.81-7.39C306.42,321.31,306.24,319.73,305.15,318.87z"
          ></path>
          <path
            className="fill-background"
            d="M252.46,231.59c0.07-0.13,0.06-0.29,0-0.42c-3.09-6.2-7.09-11.83-11.91-16.75
		c-5.07-5.18-10.91-9.46-17.37-12.7c-0.23-0.11-0.51-0.03-0.63,0.2c-4.04,7.45-6.72,15.48-7.97,23.85
		c-1.04,7.03-1.05,14.16-0.02,21.22c0.02,0.16,0.13,0.3,0.28,0.37c0.06,0.03,0.13,0.04,0.19,0.04c0.09,0,0.19-0.03,0.27-0.08
		c5.45-3.69,11.28-6.86,17.32-9.42c6.27-2.66,12.83-4.69,19.5-6.04C252.27,231.82,252.39,231.73,252.46,231.59z"
          ></path>
          <path
            className="fill-background"
            d="M341.85,225.77c-1.25-8.37-3.93-16.4-7.97-23.85c-0.12-0.23-0.41-0.31-0.63-0.2
		c-6.46,3.24-12.3,7.51-17.37,12.7c-4.81,4.92-8.82,10.56-11.91,16.76c-0.07,0.13-0.07,0.29,0,0.42c0.07,0.13,0.19,0.23,0.33,0.26
		c6.67,1.35,13.23,3.38,19.5,6.04c6.04,2.56,11.87,5.73,17.32,9.42c0.08,0.05,0.17,0.08,0.27,0.08c0.07,0,0.13-0.01,0.19-0.04
		c0.15-0.07,0.26-0.2,0.28-0.37C342.9,239.93,342.89,232.79,341.85,225.77z"
          ></path>
          <path
            className="fill-background"
            d="M251.21,284.16c-5.97-1.02-12.03,2.13-15.11,7.85c-0.42,0.78-0.4,1.72,0.06,2.48
		c0.45,0.76,1.27,1.22,2.16,1.22h20.94c0.87,0,1.68-0.45,2.13-1.19s0.5-1.66,0.11-2.44C260.93,290.95,257.8,285.28,251.21,284.16z"
          ></path>
          <path
            className="fill-background"
            d="M308.74,284.16c-5.96-1.02-12.03,2.14-15.11,7.85c-0.42,0.78-0.4,1.72,0.06,2.48
		c0.45,0.76,1.27,1.22,2.16,1.22h20.94c0.87,0,1.68-0.45,2.13-1.19s0.5-1.66,0.11-2.44C318.47,290.95,315.34,285.28,308.74,284.16z"
          ></path>
          <g>
            <path
              className="fill-background"
              d="M236.88,304.32l-16.11-4.04c-1.26-0.31-2.54,0.45-2.85,1.71c-0.32,1.26,0.45,2.54,1.71,2.85l16.11,4.04
			c0.19,0.05,0.38,0.07,0.57,0.07c1.05,0,2.01-0.71,2.28-1.78C238.9,305.91,238.14,304.63,236.88,304.32z"
            ></path>
            <path
              className="fill-background"
              d="M235.74,314.86l-16.11,4.04c-1.26,0.32-2.02,1.59-1.71,2.85c0.27,1.07,1.23,1.78,2.28,1.78
			c0.19,0,0.38-0.02,0.57-0.07l16.11-4.04c1.26-0.32,2.02-1.59,1.71-2.85C238.27,315.31,236.99,314.54,235.74,314.86z"
            ></path>
            <path
              className="fill-background"
              d="M318.86,308.95c0.19,0,0.38-0.02,0.57-0.07l16.11-4.04c1.26-0.32,2.02-1.59,1.71-2.85
			c-0.32-1.26-1.6-2.02-2.85-1.71l-16.11,4.04c-1.26,0.32-2.02,1.59-1.71,2.85C316.85,308.24,317.81,308.95,318.86,308.95z"
            ></path>
            <path
              className="fill-background"
              d="M335.54,318.9l-16.11-4.04c-1.25-0.32-2.54,0.45-2.85,1.71c-0.32,1.26,0.45,2.54,1.71,2.85l16.11,4.04
			c0.19,0.05,0.38,0.07,0.57,0.07c1.05,0,2.01-0.71,2.28-1.78C337.56,320.49,336.8,319.21,335.54,318.9z"
            ></path>
          </g>
          <path
            className="fill-foreground"
            d="M396.29,268.6c-0.09-3.51-0.5-10.74-2.18-19.68c-2.15-11.4-5.88-22.78-11.09-33.81c-6.45-13.02-15.07-24.56-25.62-34.3
		c-29.53-27.25-63.62-30.9-76.91-31.21c-42.56-1-71.78,21.5-82.35,31.21c-35.19,32.32-38.97,75.29-39.29,87.69
		c-1.12,43.61,20.9,74.04,30.42,85.1c0.16,0.18,0.36,0.33,0.58,0.43c27.39,12.54,57.75,19.17,87.79,19.17
		c26.79,0,53.04-5.05,78.04-15c0.79-0.32,1.23-1.18,1.01-2.01l-1.96-7.44c-0.13-0.5-0.5-0.92-0.97-1.13
		c-0.48-0.2-1.02-0.18-1.48,0.07c-2.42,1.33-4.92,2.57-7.43,3.7c-0.07,0.03-7.56,3.37-16.05,5.66
		c-27.79,7.49-78.11,7.44-103.55-0.12c-6.98-2.07-13.36-4.83-15.15-5.62l-0.27-0.14h-0.04c-5.14-2.36-10.14-5.14-14.87-8.26
		c-10.01-11.37-26.93-35.47-27.97-69.3c-1.81-58.46,46.39-92.2,48.44-93.61c36.94-25.33,86.85-25.33,124.21,0.01
		c49.65,33.67,48.59,93.12,48.57,93.72c-0.17,6.44-1.13,12.84-1.14,12.9c-0.83,5.49-2.12,10.98-3.82,16.31
		c-0.18,0.56-0.05,1.18,0.34,1.63c0.39,0.45,0.98,0.66,1.56,0.56l5.86-0.99c0.66-0.11,1.19-0.6,1.36-1.24
		C395.26,291.69,396.59,280.16,396.29,268.6z"
          ></path>
          <path
            className="fill-foreground"
            d="M150.39,273.29c-0.07-9.9,1.26-19.81,3.95-29.44c0.07-0.24,0.01-0.49-0.15-0.68c-0.16-0.19-0.4-0.28-0.65-0.25
		c-3.85,0.46-7.14,1.65-9.76,3.54c-6.8,4.9-6.86,13.15-6.94,25.63c-0.09,12.97-0.15,21.54,6.95,26.62c2.62,1.88,5.9,3.07,9.75,3.54
		c0.03,0,0.06,0,0.09,0c0.21,0,0.42-0.09,0.56-0.26c0.16-0.19,0.22-0.44,0.15-0.68C151.78,292.14,150.45,282.71,150.39,273.29z"
          ></path>
          <path
            className="fill-foreground"
            d="M419.01,272.08c-0.08-12.48-0.14-20.73-6.94-25.63c-2.62-1.89-5.9-3.08-9.76-3.54c-0.25-0.03-0.49,0.06-0.65,0.25
		c-0.16,0.19-0.22,0.44-0.15,0.68c2.69,9.63,4.02,19.54,3.95,29.44c-0.06,9.43-1.39,18.86-3.95,28.03
		c-0.07,0.24-0.01,0.49,0.15,0.68c0.14,0.17,0.35,0.26,0.56,0.26c0.03,0,0.06,0,0.09,0c3.85-0.47,7.13-1.66,9.75-3.54
		C419.16,293.62,419.1,285.05,419.01,272.08z"
          ></path>
          <path
            className="fill-foreground"
            d="M277.48,383.37c-9.23,0-16.74,7.63-16.74,17s7.51,17,16.74,17c9.23,0,16.74-7.63,16.74-17S286.71,383.37,277.48,383.37z"
          ></path>
          <polygon
            className="fill-background"
            points="275.37,405.93 275.37,402.2 284.07,402.2 284.07,398.49 275.37,398.49 275.37,394.8 285.01,394.8 
		285.01,391.09 270.73,391.09 270.73,409.64 285.3,409.64 285.3,405.93 	"
          ></polygon>
          <g>
            <path
              className="fill-foreground"
              d="M256.86,383.32c-15.73-1.21-31.3-4.18-46.28-8.82c-8.41-2.61-16.75-5.78-24.79-9.44c-0.23-0.11-0.5-0.08-0.71,0.05
			c-0.21,0.14-0.34,0.37-0.34,0.62l0,11.86c0,0.3,0.18,0.56,0.45,0.68c7.95,3.43,16.18,6.42,24.46,8.88
			c13.73,4.08,27.93,6.81,42.21,8.13c0.02,0,0.05,0,0.07,0c0.34,0,0.65-0.24,0.72-0.58c0.47-2.14,1.2-4.21,2.19-6.15
			c0.72-1.42,1.57-2.77,2.55-4.03c0.17-0.22,0.2-0.5,0.09-0.75C257.37,383.5,257.13,383.34,256.86,383.32z"
            ></path>
            <path
              className="fill-foreground"
              d="M360.34,369.19c-0.2-0.14-0.46-0.17-0.69-0.08c-0.47,0.19-0.95,0.37-1.42,0.56c-4.55,1.76-9.2,3.39-13.83,4.82
			c-15,4.65-30.58,7.62-46.32,8.82c-0.27,0.02-0.51,0.19-0.62,0.44c-0.11,0.25-0.07,0.54,0.1,0.76c1.39,1.77,2.53,3.71,3.38,5.78
			c0.58,1.42,1.04,2.9,1.35,4.4c0.07,0.35,0.38,0.59,0.72,0.59c0.02,0,0.05,0,0.07,0c14.3-1.32,28.52-4.06,42.26-8.14
			c5-1.48,10-3.17,14.86-5c0.29-0.11,0.48-0.38,0.48-0.69V369.8C360.66,369.55,360.54,369.32,360.34,369.19z"
            ></path>
          </g>
          <path
            className="fill-foreground"
            d="M418.84,365.59c-7.49,0.92-15.12,1.39-22.67,1.39h-0.29c-7.44-0.01-14.97-0.48-22.37-1.39l-0.79-0.1v18.38
		c0,10.5,10.52,19.04,23.46,19.04s23.46-8.54,23.46-19.04V365.5L418.84,365.59z"
          ></path>
          <path
            className="fill-background"
            d="M414.69,384.15c-0.4,1.65-1.02,3.24-1.79,4.75c-0.79,1.5-1.75,2.93-2.95,4.13c-0.89,0.9-2.35,0.9-3.24,0.01
		c-0.82-0.81-0.9-2.09-0.23-2.99l0.02-0.03c0.81-1.09,1.6-2.18,2.27-3.36c0.68-1.17,1.22-2.41,1.63-3.71c0.43-1.29,0.74-2.63,0.92-4
		c0.2-1.38,0.3-2.78,0.38-4.24l0-0.04c0.04-0.75,0.69-1.33,1.44-1.29c0.56,0.03,1.02,0.4,1.2,0.89c0.57,1.55,0.83,3.2,0.88,4.87
		C415.26,380.82,415.09,382.51,414.69,384.15z"
          ></path>
          <path
            className="fill-foreground"
            d="M427.82,335.89c-2.88-13.29-18.31-21.87-31.8-21.87c-13.49,0-29.47,8.56-31.8,21.87c-0.96,5.5,0.23,12.76,4.88,17.48
		c1.13,1.14,2.68,2.36,7.64,3.22v-10.98c0-1.52,1.24-2.76,2.76-2.76c1.52,0,2.76,1.24,2.76,2.76v11.65
		c2.95,0.25,6.62,0.42,11.18,0.48v-12.12c0-1.52,1.24-2.76,2.76-2.76c1.52,0,2.76,1.24,2.76,2.76v12.15
		c4.73-0.03,8.55-0.18,11.62-0.42v-11.73c0-1.52,1.24-2.76,2.76-2.76c1.52,0,2.76,1.24,2.76,2.76v11.1c5.54-0.9,7.09-2.22,8.1-3.34
		C430.05,346.86,428.01,336.78,427.82,335.89z"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Logo;
