exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-modules-index-tsx": () => import("./../../../src/pages/admin/modules/index.tsx" /* webpackChunkName: "component---src-pages-admin-modules-index-tsx" */),
  "component---src-pages-admin-modules-module-splat-tsx": () => import("./../../../src/pages/admin/modules/[moduleSplat].tsx" /* webpackChunkName: "component---src-pages-admin-modules-module-splat-tsx" */),
  "component---src-pages-admin-organisations-index-tsx": () => import("./../../../src/pages/admin/organisations/index.tsx" /* webpackChunkName: "component---src-pages-admin-organisations-index-tsx" */),
  "component---src-pages-admin-organisations-organisations-id-tsx": () => import("./../../../src/pages/admin/organisations/[organisationsId].tsx" /* webpackChunkName: "component---src-pages-admin-organisations-organisations-id-tsx" */),
  "component---src-pages-admin-pathways-index-tsx": () => import("./../../../src/pages/admin/pathways/index.tsx" /* webpackChunkName: "component---src-pages-admin-pathways-index-tsx" */),
  "component---src-pages-admin-pathways-pathways-id-tsx": () => import("./../../../src/pages/admin/pathways/[pathwaysId].tsx" /* webpackChunkName: "component---src-pages-admin-pathways-pathways-id-tsx" */),
  "component---src-pages-admin-users-index-tsx": () => import("./../../../src/pages/admin/users/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-index-tsx" */),
  "component---src-pages-admin-users-user-id-tsx": () => import("./../../../src/pages/admin/users/[userId].tsx" /* webpackChunkName: "component---src-pages-admin-users-user-id-tsx" */),
  "component---src-pages-admin-videos-index-tsx": () => import("./../../../src/pages/admin/videos/index.tsx" /* webpackChunkName: "component---src-pages-admin-videos-index-tsx" */),
  "component---src-pages-admin-videos-video-id-tsx": () => import("./../../../src/pages/admin/videos/[videoId].tsx" /* webpackChunkName: "component---src-pages-admin-videos-video-id-tsx" */),
  "component---src-pages-auth-tsx": () => import("./../../../src/pages/auth.tsx" /* webpackChunkName: "component---src-pages-auth-tsx" */),
  "component---src-pages-creator-modules-index-tsx": () => import("./../../../src/pages/creator/modules/index.tsx" /* webpackChunkName: "component---src-pages-creator-modules-index-tsx" */),
  "component---src-pages-creator-modules-module-id-index-tsx": () => import("./../../../src/pages/creator/modules/[moduleId]/index.tsx" /* webpackChunkName: "component---src-pages-creator-modules-module-id-index-tsx" */),
  "component---src-pages-creator-pathways-index-tsx": () => import("./../../../src/pages/creator/pathways/index.tsx" /* webpackChunkName: "component---src-pages-creator-pathways-index-tsx" */),
  "component---src-pages-creator-pathways-pathways-id-tsx": () => import("./../../../src/pages/creator/pathways/[pathwaysId].tsx" /* webpackChunkName: "component---src-pages-creator-pathways-pathways-id-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-module-modulesplat-tsx": () => import("./../../../src/pages/module/[...modulesplat].tsx" /* webpackChunkName: "component---src-pages-module-modulesplat-tsx" */),
  "component---src-pages-user-favourites-tsx": () => import("./../../../src/pages/user/favourites.tsx" /* webpackChunkName: "component---src-pages-user-favourites-tsx" */),
  "component---src-pages-user-modules-tsx": () => import("./../../../src/pages/user/modules.tsx" /* webpackChunkName: "component---src-pages-user-modules-tsx" */),
  "component---src-pages-user-organisation-tsx": () => import("./../../../src/pages/user/organisation.tsx" /* webpackChunkName: "component---src-pages-user-organisation-tsx" */),
  "component---src-pages-user-pathways-tsx": () => import("./../../../src/pages/user/pathways.tsx" /* webpackChunkName: "component---src-pages-user-pathways-tsx" */),
  "component---src-pages-user-profile-billing-index-tsx": () => import("./../../../src/pages/user/profile/billing/index.tsx" /* webpackChunkName: "component---src-pages-user-profile-billing-index-tsx" */),
  "component---src-pages-user-profile-billing-session-id-tsx": () => import("./../../../src/pages/user/profile/billing/[sessionId].tsx" /* webpackChunkName: "component---src-pages-user-profile-billing-session-id-tsx" */),
  "component---src-pages-user-profile-security-tsx": () => import("./../../../src/pages/user/profile/security.tsx" /* webpackChunkName: "component---src-pages-user-profile-security-tsx" */),
  "component---src-pages-user-profile-settings-tsx": () => import("./../../../src/pages/user/profile/settings.tsx" /* webpackChunkName: "component---src-pages-user-profile-settings-tsx" */)
}

