import { CommonMetaSchema } from "./common";
import { z } from "./xzod";

export const CommunityQuestionPropertiesSchema = z.object({
  question: z.string().meta({ component: "textarea" }),
});

export const CommunityQuestionGeneratedPropertiesSchema = z.object({
  publishedAt: z.union([z.date(), z.undefined(), z.null()]).optional(),
  createdAt: z.date(),

  // updatedAt: z.date(),
});

export const CommunityQuestionFormSchema =
  CommunityQuestionPropertiesSchema.extend({
    replyToId: z.string().optional(),
    parentId: z.string().optional(),
  });

export const CommunityQuestionSubSchema =
  CommunityQuestionPropertiesSchema.merge(
    CommunityQuestionGeneratedPropertiesSchema,
  );

export const CommunityQuestionDocumentSchema = z.object({
  _id: z.string(),
  versions: z.array(
    CommunityQuestionSubSchema.extend({ publishedAt: z.date() }).or(
      CommunityQuestionSubSchema.partial().extend({
        publishedAt: z.null().or(z.undefined()),
      }),
    ),
  ),
  upvote: z.array(z.string()),
  downvote: z.array(z.string()),
  replyToId: z.string().optional().nullable(),
  parentId: z.string().optional().nullable(),
});

type TCommunityQuestionStackedDocumentSchema = z.infer<
  typeof CommunityQuestionDocumentSchema
> & {
  replies?: TCommunityQuestionStackedDocumentSchema[] | undefined | null;
} & z.infer<typeof CommonMetaSchema>;

export const CommunityQuestionStackedDocumentSchema: z.ZodType<TCommunityQuestionStackedDocumentSchema> =
  CommunityQuestionDocumentSchema.extend({
    replies: z.lazy(() =>
      CommunityQuestionStackedDocumentSchema.array().optional().nullable(),
    ),
  });
