import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "tailwind/utils";

export const loaderVariants = cva(
  "flex h-full items-center justify-center bg-transparent",
  {
    variants: {
      size: {
        default: "space-x-2",
        xxs: "space-x-0.5",
        xs: "space-x-1",
        sm: "space-x-1",
        lg: "space-x-3",
        xl: "space-x-4",
      },
    },
    defaultVariants: {
      size: "default",
    },
  },
);

export const dotVariants = cva(
  "animate-bounce rounded-full bg-foreground/50 aspect-square",
  {
    variants: {
      size: {
        default: "h-4",
        xxs: "h-1",
        xs: "h-2",
        sm: "h-3",
        lg: "h-5",
        xl: "h-7",
      },
    },
    defaultVariants: {
      size: "default",
    },
  },
);

export const squareVariants = cva(
  "animate-spin border-muted-foreground aspect-square",
  {
    variants: {
      size: {
        default: "h-8 border-[3px] rounded-md",
        xxs: "h-3 border-[1px] rounded-sm",
        xs: "h-5 border-[2px] rounded-sm",
        sm: "h-6 border-[3px] rounded-sm",
        lg: "h-10 border-[4px]rounded-md",
        xl: "h-12 border-[4px]rounded-md",
      },
    },
    defaultVariants: {
      size: "default",
    },
  },
);

export interface ButtonProps extends VariantProps<typeof loaderVariants> {
  className?: string;
  dotClassName?: string;
}

export const SquareLoader: React.FC<ButtonProps> = ({ className, size }) => (
  <div className="grid place-items-center">
    <div className={cn(squareVariants({ className, size }))} />
  </div>
);

export const SmallLoader: React.FC<ButtonProps> = ({
  className,
  dotClassName,
  size,
}) => (
  <div
    className={cn(loaderVariants({ className, size }))}
    aria-label="Loading..."
    role="status"
  >
    <span className="sr-only">Loading...</span>
    <div
      className={cn(
        "[animation-delay:-0.3s]",
        dotVariants({ className: dotClassName, size }),
      )}
    ></div>
    <div
      className={cn(
        "[animation-delay:-0.15s]",
        dotVariants({ className: dotClassName, size }),
      )}
    ></div>
    <div className={cn(dotVariants({ className: dotClassName, size }))}></div>
  </div>
);

SmallLoader.displayName = "Loader";
