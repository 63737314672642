export enum LAYOUT_TYPES {
  AUTH = "auth",
  ADMIN = "admin",
  CREATOR = "creator",
  PROFILE = "profile",
  APPLICATION = "application",
}

export const userNavigation = [
  {
    name: "Profile",
    href: "/user/profile/settings",
    current: true,
    icon: "person",
  },
];

export const creatorNavigation = [
  // {
  //   name: "Pathways",
  //   href: "/creator/pathways",
  //   current: true,
  //   icon: "book",
  // },
  {
    name: "Modules",
    href: "/creator/modules",
    current: false,
    icon: "book",
  },
];

export const adminNavigation = [
  // {
  //   name: "Pathways",
  //   href: "/admin/pathways",
  //   current: true,
  //   icon: "book",
  // },
  {
    name: "Modules",
    href: "/admin/modules",
    current: false,
    icon: "book",
  },
  // {
  //   name: "Organisations",
  //   href: "/admin/organisations",
  //   current: false,
  //   icon: "book",
  // },
  { name: "Users", href: "/admin/users", current: false, icon: "book" },
];

export const profileNavigation = [
  {
    name: "Profile",
    href: "/user/profile/settings",
    current: true,
    icon: "person",
  },
  {
    name: "Security",
    href: "/user/profile/security",
    current: true,
    icon: "person",
  },
  {
    name: "Billing",
    href: "/user/profile/billing",
    current: true,
    icon: "credit_card",
  },
  {
    name: "Modules",
    href: "/user/modules",
    current: true,
    icon: "book",
  },
  // {
  //   name: "Pathways & Certifications",
  //   href: "/user/cerficiations",
  //   current: true,
  //   icon: "verified_user",
  // },
];

export const layoutNavigation = {
  [LAYOUT_TYPES.ADMIN]: adminNavigation,
  [LAYOUT_TYPES.CREATOR]: creatorNavigation,
  [LAYOUT_TYPES.PROFILE]: profileNavigation,
  [LAYOUT_TYPES.APPLICATION]: userNavigation,
};
