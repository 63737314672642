import { z } from "./xzod";

export const NotificationDocumentSchema = z.object({
  _id: z.string(),
  permissions: z.array(z.string()),
  title: z.string(),
  body: z.string(),
  data: z.object({
    collection: z.string(),
    _id: z.string(),
  }),
  readBy: z.array(z.string()),
  createdAt: z.date(),
  updatedAt: z.date(),
});
